import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql, PageProps } from "gatsby"

import LayoutMdx from "../components/LayoutMdx"

import { Seo } from "../components/Seo/Seo"
import {
  Navigation,
  Blog,
  Footer,
  Hero,
  Breadcrumb,
} from "../components/Marketing"
import { ArticleEntity } from "../entities/ArticleEntity"
import { H2, Haikus, Separator } from "../components/article"
import { getImage } from "gatsby-plugin-image"

const BiographyPage: React.FC<PageProps<
  {
    allHaikusTsv: {
      edges: Array<{ node: { content: string; author_id: string } }>
    }
    authorsTsv: {
      deathdate: string
      birthdate: string
      name: string
      country: string
      id: string
    }
  },
  ArticleEntity & { more: ArticleEntity[] }
>> = props => {
  const article = props.pageContext
  const categories = article.categories
  const haikus = props.data.allHaikusTsv.edges
  const author = props.data.authorsTsv
  const illustration = getImage(article.image)

  const breadcrumbs: Array<{
    label: string
    url: string
  }> = categories.map(category => ({ label: category.name, url: category.url }))

  if (!article.isCategory)
    breadcrumbs.push({ label: article.display__title, url: article.url })

  return (
    <LayoutMdx>
      <Seo
        article={{ ...article, image: illustration.images.fallback.src }}
        image={illustration.images.fallback.src}
        title={article.seo__title}
        description={article.seo__description}
        breadcrumbs={breadcrumbs}
      />
      <Navigation />
      <Hero
        theme={{
          as: "nav",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          raw: true,
          value: article.title,
        }}
        description={{
          as: "div",
          value: (
            <>
              <p>{article.description}</p>

              <h3 className="mt-10 text-sm font-bold text-gray-800 uppercase">
                Date de naissance et de décès
              </h3>
              <p>
                {author.birthdate} - {author.deathdate}
              </p>

              <h3 className="mt-10 text-sm font-bold text-gray-800 uppercase">
                Nationalité
              </h3>

              <p>{author.country}</p>
            </>
          ),
        }}
        date={{
          created_at: article.created_at,
          updated_at: article.updated_at,
        }}
        reading={article.reading}
        illustration={illustration}
        illustrationCredit={article.image_credit}
      />

      <Separator />

      <MDXRenderer>{article.html}</MDXRenderer>

      <Haikus
        haikus={haikus.map(({ node }) => ({
          content: node.content,
          author: {
            fullName: author.name,
          },
        }))}
      />

      <Footer />
    </LayoutMdx>
  )
}

export default BiographyPage

export const pageQuery = graphql`
  query BiographyPage($biography__author: String) {
    allHaikusTsv(filter: { author_id: { eq: $biography__author } }) {
      edges {
        node {
          id
          content
          theme
          author_id
        }
      }
    }
    authorsTsv(id: { eq: $biography__author }) {
      id
      country
      deathdate
      birthdate
      name
    }
  }
`
